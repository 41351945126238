import { useEffect } from "react";
import CustomCard from "../components/CustomCard.js";
import CompareQuestion from "../components/CompareQuestion.js";
import { useFormContext } from "react-hook-form";

const CompareCategories = ({ actor, onBack, onNext }) => {
  const { getValues, setValue } = useFormContext();
  const categories = getValues("assessment")
    .filter((category) => category.subsections.some((subsection) => subsection.factors.some((factor) => factor.value !== "na")))
    .map((category) => category.title);

  useEffect(() => {
    setValue("compareCategories", []);
    setValue("categories", categories);
    for (let i = 0; i < categories.length; i++) {
      for (let j = 0; j < categories.length; j++) {
        setValue(`compareCategories.${i}.${j}`, 1);
      }
    }
  }, []);

  return (
    <>
      <CustomCard header={`${actor} Role`} onBack={onBack} onNext={onNext} />
      <CustomCard title="Compare Categories">
        {categories.map((category1, categoryIndex1) =>
          Array.from({ length: categories.length - categoryIndex1 - 1 }, (_, i) => categoryIndex1 + i + 1).map((categoryIndex2) => (
            <CompareQuestion
              key={`${categoryIndex1}.${categoryIndex2}`}
              path="compareCategories"
              row={categoryIndex1}
              col={categoryIndex2}
              side1={category1}
              side2={categories[categoryIndex2]}
            />
          ))
        )}
      </CustomCard>
    </>
  );
};

export default CompareCategories;
