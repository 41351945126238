import { useEffect } from "react";
import CustomCard from "../components/CustomCard.js";
import CompareQuestion from "../components/CompareQuestion.js";
import { useFormContext } from "react-hook-form";

const CompareFactors = ({ actor, onBack, onNext }) => {
  const { getValues, setValue } = useFormContext();
  const categories = getValues("assessment")
    .filter((category) => category.subsections.some((subsection) => subsection.factors.some((factor) => factor.title !== "na")))
    .map((category) => category.title);
  const factors = categories.map((_, index) =>
    getValues(`assessment.${index}.subsections`).reduce((acc, subsection) => [...acc, ...subsection.factors.map((factor) => factor.title)], [])
  );

  useEffect(() => {
    setValue("compareFactors", []);
    setValue("factors", factors);
    for (let x = 0; x < categories.length; x++) {
      const factors = getValues(`assessment.${x}.subsections`).reduce(
        (acc, subsection) => [...acc, ...subsection.factors.map((factor) => factor.title)],
        []
      );
      for (let i = 0; i < factors.length; i++) {
        for (let j = 0; j < factors.length; j++) {
          setValue(`compareFactors.${x}.${i}.${j}`, i === j ? 1 : 1);
        }
      }
    }
  }, []);

  return (
    <>
      <CustomCard header={`${actor} Role`} onBack={onBack} onNext={onNext} />
      {categories.map((category, index) => (
        <CustomCard key={index} title={`Compare ${category} Factors`}>
          {factors[index].map((factor1, factorIndex1) =>
            Array.from({ length: factors[index].length - factorIndex1 - 1 }, (_, i) => factorIndex1 + i + 1).map((factorIndex2) => (
              <CompareQuestion
                key={`${index}.${factorIndex1}.${factorIndex2}`}
                path={`compareFactors.${index}`}
                row={factorIndex1}
                col={factorIndex2}
                side1={factor1}
                side2={factors[index][factorIndex2]}
              />
            ))
          )}
        </CustomCard>
      ))}
    </>
  );
};

export default CompareFactors;
