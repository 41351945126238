export const data = [
  {
    title: "Organization",
    subsections: [
      {
        title: "Characteristics",
        factors: [
          {
            title: "Organization Size",
            options: [
              "Organization size may not be a significant factor",
              "The size of our organization slows the process",
              "The size of our organization facilitates the adoption process",
            ],
          },
          {
            title: "Innovation Atmosphere",
            options: [
              "We have a very conservative or low-innovation environment",
              "Our innovation atmosphere is neutral and doesn't reject new ideas",
              "We have a strong innovation culture that actively drives innovation adoption",
            ],
          },
          {
            title: "Previous Experience",
            options: [
              "We have negative past experiences with adopting new ideas",
              "Our previous experience does not significantly impact the adoption decision",
              "We have prior successes with similar adoptions that promote our confidence and willingness",
            ],
          },
        ],
      },
      {
        title: "Management",
        factors: [
          {
            title: "Strategic Planning",
            options: [
              "Our strategic plans do not align with cloud manufacturing",
              "Strategic planning may not be a significant factor",
              "Cloud manufacturing aligns well with our organization's strategic objectives",
            ],
          },
          {
            title: "Top Management Support",
            options: [
              "There is a lack of support from top management",
              "Top management support has a negligible effect on the adoption decision",
              "Our top management is supporting the adoption",
            ],
          },
          {
            title: "Buyer-Supplier Relationship",
            options: [
              "The adoption would weaken our buyer-supplier relationships",
              "The adoption wouldn't affect our buyer-supplier relationships",
              "The adoption would empower our buyer-supplier relationships",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Business",
    subsections: [
      {
        title: "Marketing",
        factors: [
          {
            title: "Competitive Advantage",
            options: [
              "The competitive advantage impact is negligible",
              "The adoption would at least make us as everyone else",
              "The adoption would make us ahead of our competitors",
            ],
          },
          {
            title: "Brand Image",
            options: [
              "The adoption conflicts with our brand image",
              "Brand image had little impact on the decision",
              "The adoption would enhance our brand image",
            ],
          },
        ],
      },
      {
        title: "Financial",
        factors: [
          {
            title: "Cost-Benefit Analysis",
            options: [
              "The adoption is associated with high costs and uncertain return on investment",
              "The adoption benefits somewhat outweigh the costs",
              "The adoption would result in strong economic benefits",
            ],
          },
          {
            title: "Demand Analysis",
            options: [
              "The demand is very limited and inconsistent",
              "The market demand is not high but htere are some indications of potential growth",
              "There is a strong business case and market demand",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Human",
    subsections: [
      {
        title: "Mindset",
        factors: [
          {
            title: "Perceived Usefulness",
            options: [
              "Cloud manufacturing is perceived as useless and ineffective",
              "Limited usefulness is perceived",
              "Cloud manufacturing is perceived as highly useful and beneficial",
            ],
          },
          {
            title: "Perceived Risk",
            options: [
              "The perceived risks are very high and out of control",
              "The perceived risks are serious but can be delt with",
              "The perceived risks are low and manageable",
            ],
          },
          {
            title: "Perceived Ease of Use",
            options: [
              "The tool is perceived as complicated",
              "Perceived ease of use does not notably impact the decision",
              "The tool is perceived as user-friendly",
            ],
          },
          {
            title: "Attitude Toward Sharing",
            options: [
              "We have a negative attitude toward resource sharing/using shared resources",
              "Attitudes toward sharing have little influence on the adoption",
              "We have a positive attitude toward resource sharing/using shared resources",
            ],
          },
          {
            title: "Attitude Toward Change",
            options: [
              "We firmly resist change and tend to keep things as is",
              "We reluctantly accept changes but take time to adapt",
              "We openly welcome change and easily adapt",
            ],
          },
        ],
      },
      {
        title: "Competency",
        factors: [
          {
            title: "Digital Skills",
            options: [
              "We lack digital-skilled workforce within the organization",
              "We have adequate digital-skilled workforce within the organization",
              "We have highly digital-skilled workforce within the organization",
            ],
          },
          {
            title: "Training",
            options: [
              "The training programs are very limited/insufficient",
              "the training programs are acceptable/not easy to afford",
              "There are enough training programs/we afford developing the training programs in-house",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Technology",
    subsections: [
      {
        title: "Maintainability",
        factors: [
          {
            title: "Compatibility",
            options: [
              "Cloud manufacturing is incompatible with existing systems and infrastructure",
              "Some of the existing sytems and infrastructure are compatible with cloud manufacturing",
              "Cloud manufacturing is highly compatible with existing sytems and infrastructure",
            ],
          },
          {
            title: "IT Support",
            options: ["There is no IT support", "There is limited IT support", "There is strong IT support"],
          },
          {
            title: "Security and Data Protection",
            options: [
              "The security measures are not enough/our data is highly sensitive",
              "The security measures are acceptable/the data security risk can be mitigated",
              "There are robust security measures and data protection policies/we don't have sensitive data",
            ],
          },
        ],
      },
      {
        title: "Establishment",
        factors: [
          {
            title: "Complexity and Architecture",
            options: [
              "The model is complex and rigid",
              "Complexity and architecture have little effect on the decision",
              "The model is simple, adaptable, and user-friendly",
            ],
          },
          {
            title: "Resource Readiness",
            options: [
              "There is no ready resources",
              "There are some ready resources for connection",
              "The available resources are ready to be connected",
            ],
          },
          {
            title: "Cloud Readiness",
            options: [
              "The organization's infrastructure is not ready to adopt cloud-based solutions",
              "The organization's infrastructure needs further preparation",
              "The organization's infrastructure is cloud-ready",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Environment",
    subsections: [
      {
        title: "Industry",
        factors: [
          {
            title: "Market Pressure",
            options: [
              "Market pressure had little to no influence on the adoption decision",
              "The market pressure is not high which decreases the urgency to adopt",
              "There is a strong market pressure that incentivizes rapid adoption",
            ],
          },
          {
            title: "Market Uncertainty",
            options: [
              "Market uncertainty may not have a significant impact on the decision",
              "The market is stable or predictable which reduces the need for the adoption",
              "The market is dynamic with emerging opportunities which encourages adoption as a way to remain flexible and adaptable",
            ],
          },
          {
            title: "Consumer Readiness",
            options: [
              "Consumer readiness has no notable impact on the decision",
              "Low consumer awareness or resistance to change",
              "Consumers are ready and supportive",
            ],
          },
        ],
      },
      {
        title: "Legislation",
        factors: [
          {
            title: "Government Regulations",
            options: [
              "Government regulations may have a neutral impact",
              "Government regulations are still restrictive or unclear",
              "There are supportive government regulations that encourage and simplify the adoption",
            ],
          },
          {
            title: "Clear Cloud Usage Policy",
            options: [
              "A lack of cloud usage policy complicates the adoption",
              "The cloud usage policy needs to be clarified",
              "There is a clear and well-defined cloud usage policy that facilitates adoption",
            ],
          },
        ],
      },
    ],
  },
];
