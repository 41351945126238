import { Card, Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import Box from "@mui/system/Box";
import Slider from "@mui/material/Slider";

const CompareQuestion = ({ path, row, col, side1, side2, ...rest }) => {
  const { setValue, watch } = useFormContext();
  const idPrefix = `${path}.${row}.${col}`;

  const valueMap = [0, 4, 3, 2, 1, 1 / 2, 1 / 3, 1 / 4];

  // const onCompare = (e) => {
  //   setValue(`${path}.${row}.${col}`, Number(e.target.value));
  //   setValue(`${path}.${col}.${row}`, 1 / e.target.value);
  // };

  const onSliderChange = (_, value) => {
    setValue(`${path}.${row}.${col}`, valueMap[value]);
    setValue(`${path}.${col}.${row}`, 1 / valueMap[value]);
  };

  const currentValue = valueMap.findIndex((value) => watch(idPrefix) === value);

  const sliderMarks = [
    { value: 0, label: "" },
    { value: 1, label: `${side1} is much more important` },
    { value: 2, label: `${side1} is strongly more important` },
    { value: 3, label: `${side1} is moderately more important` },
    { value: 4, label: `${side1} and ${side2} are equally important` },
    { value: 5, label: `${side2} is moderately more important` },
    { value: 6, label: `${side2} is strongly more important` },
    { value: 7, label: `${side2} is much more important` },
  ];

  return (
    <div className="mx-3 mb-4" {...rest}>
      <Row className="fw-bold">
        <Col xs="5">{side1}</Col>
        <Col xs="2" className="text-center">
          vs
        </Col>
        <Col xs="5" className="text-end">
          {side2}
        </Col>
      </Row>
      {/* <Card.Text className="mb-1">{`${side1} vs ${side2}:`}</Card.Text> */}
      <Box>
        <Slider
          aria-label="Comparison"
          defaultValue={4}
          shiftStep={1}
          step={1}
          min={1}
          max={7}
          marks
          track={false}
          value={currentValue}
          onChange={onSliderChange}
        />
      </Box>
      <Card.Text className="text-center">{sliderMarks[valueMap.findIndex((value) => value === watch(idPrefix))]?.label}</Card.Text>
      {/* <Form.Group as={Row} className="mb-3">
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side1} is much more important`}
            value={4}
            id={`${idPrefix}.1`}
            checked={watch(idPrefix) === 4}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side1} is strongly more important`}
            value={3}
            id={`${idPrefix}.2`}
            checked={watch(idPrefix) === 3}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side1} is moderately more important`}
            value={2}
            id={`${idPrefix}.3`}
            checked={watch(idPrefix) === 2}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side1} and ${side2} are equally important`}
            value={1}
            id={`${idPrefix}.4`}
            checked={watch(idPrefix) === 1}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side2} is moderately more important`}
            value={1 / 2}
            id={`${idPrefix}.5`}
            checked={watch(idPrefix) === 1 / 2}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side2} is strongly more important`}
            value={1 / 3}
            id={`${idPrefix}.6`}
            checked={watch(idPrefix) === 1 / 3}
            onChange={onCompare}
          />
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Check
            type="radio"
            label={`${side2} is much more important`}
            value={1 / 4}
            id={`${idPrefix}.7`}
            checked={watch(idPrefix) === 1 / 4}
            onChange={onCompare}
          />
        </Col>
      </Form.Group> */}
    </div>
  );
};

export default CompareQuestion;
