import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { data } from "./data.js";
import ActorSelection from "./pages/ActorSelection.js";
import MainLayout from "./layouts/MainLayout.js";
import Assessment from "./pages/Assessment.js";
import CompareCategories from "./pages/CompareCategories.js";
import CompareFactors from "./pages/CompareFactors.js";
import Result from "./pages/Result.js";

function App() {
  const [actor, setActor] = useState("");
  const [step, setStep] = useState(1);
  const [showResults, setShowResults] = useState(false);
  const formObject = useForm({ defaultValues: { assessment: data } });
  const { watch, getValues } = formObject;
  const factorValues = {
    na: 0,
    low: 1,
    medium: 2,
    high: 3,
  };

  useEffect(() => {
    const subscription = watch(() => console.log(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  const pairwiseCompare = (matrix) => {
    const columnSums = matrix.map((_, i) => matrix.reduce((acc, row) => acc + row[i], 0));
    const normalizedMatrix = matrix.map((row) => row.map((col, j) => col / columnSums[j]));
    const weights = normalizedMatrix.map((row) => row.reduce((acc, col) => acc + col, 0) / row.length);
    return weights;
  };

  const formatData = () => {
    const categoryComparison = pairwiseCompare(getValues("compareCategories"));
    const factorComparison = categoryComparison.map((_, i) => pairwiseCompare(getValues(`compareFactors.${i}`)));

    const categories = getValues("categories");
    const factors = getValues("factors");

    // console.log("categoryComparison", categoryComparison);
    // console.log("factorComparison", factorComparison);
    // console.log("categories", categories);
    // console.log("factors", factors);

    const formattedData = getValues("assessment").reduce((acc, category) => {
      const formattedCategoryFactors = category.subsections.reduce(
        (acc2, subsection) => [
          ...acc2,
          ...subsection.factors.map((factor) => ({
            category: category.title,
            subsection: subsection.title,
            factor: factor.title,
            value: factor.value,
            angle:
              360.0 *
              categoryComparison[categories.findIndex((cat) => cat === category.title)] *
              factorComparison[categories.findIndex((cat) => cat === category.title)][
                factors[categories.findIndex((cat) => cat === category.title)].findIndex((fac) => fac === factor.title)
              ],
          })),
        ],
        []
      );
      const factorsWithWeights = formattedCategoryFactors.map((factor) => ({ ...factor, weight: factor.angle / 7.2 }));
      const sortedCategoryFactors = factorsWithWeights?.sort((a, b) => factorValues[a.value] - factorValues[b.value]);
      return [...acc, ...sortedCategoryFactors];
    }, []);
    return formattedData.map((item) => ({ ...item, value: factorValues[item.value] }));
  };

  return (
    <FormProvider {...formObject}>
      <div style={{ backgroundColor: "#EDF2F9" }}>
        <div className="container p-4 min-vh-100">
          {!actor ? (
            <ActorSelection onSelectActor={setActor} />
          ) : !showResults ? (
            <Form onSubmit={() => setShowResults(true)}>
              <MainLayout>
                {step === 1 ? (
                  <Assessment actor={actor} onBack={() => setActor("")} onNext={() => setStep(2)} />
                ) : step === 2 ? (
                  <CompareCategories actor={actor} onBack={() => setStep(1)} onNext={() => setStep(3)} />
                ) : step === 3 ? (
                  <CompareFactors actor={actor} onBack={() => setStep(2)} onNext={() => setShowResults(true)} />
                ) : null}
              </MainLayout>
            </Form>
          ) : (
            <Result resultData={formatData()} onBack={() => setShowResults(false)} rawData={getValues()} />
          )}
        </div>
      </div>
    </FormProvider>
  );
}

export default App;
