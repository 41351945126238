import { useState } from "react";
import { Card } from "react-bootstrap";

const ActorButton = ({ title, onSelectActor, image }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <Card
      className="overflow-hidden"
      style={{
        cursor: "pointer",
        backgroundColor: hovering ? "rgb(249, 250, 253)" : "#FFFFFF",
        borderColor: "rgba(0, 0, 0, 0.125",
        borderWidth: 0,
        boxShadow: "rgba(65, 69, 88, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px",
        color: "rgb(94, 110, 130)",
        minHeight: "6rem",
      }}
      onClick={() => onSelectActor(title)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          minHeight: "100%",
          top: 0,
          left: 0,
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflow: "hidden",
          willChange: "transform, opacity, filter",
          backfaceVisibility: "hidden",
          backgroundRepeat: "no-repeat",
          borderTopRightRadius: "0.375rem",
          borderBottomLeftRadius: "0.375rem",
          backgroundImage: `url(${image})`,
        }}
      />
      <Card.Body className="position-relative d-flex align-items-center">
        <h5 className="text-primary w-75">{title}</h5>
      </Card.Body>
    </Card>
  );
};

export default ActorButton;
