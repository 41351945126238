import { Card, Button } from "react-bootstrap";

const CustomCard = ({ title, header, onBack, onNext, children, ...rest }) => {
  return (
    <Card
      {...rest}
      className="mb-3"
      style={{
        backgroundColor: "#FFFFFF",
        borderColor: "rgba(0, 0, 0, 0.125)",
        borderWidth: 0,
        boxShadow: "rgba(65, 69, 88, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px",
        color: "rgb(94, 110, 130)",
      }}
    >
      {title && (
        <Card.Header
          className="py-3"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderBottomColor: "rgba(0, 0, 0, 0.125)",
            borderBottomWidth: "0px",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
          }}
        >
          <Card.Title as="h3" className="m-0">
            {title}
          </Card.Title>
        </Card.Header>
      )}
      {title ? (
        <Card.Body
          style={{
            backgroundColor: "rgb(249, 250, 253)",
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          {children}
        </Card.Body>
      ) : (
        <Card.Body
          className="py-3 d-flex flex-wrap flex-between-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderBottomColor: "rgba(0, 0, 0, 0.125)",
            borderBottomWidth: "0px",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex flex-wrap align-items-center">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={onBack}
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                borderColor: "rgb(255, 255, 255)",
                boxShadow:
                  "rgba(43, 45, 80, 0.1) 0px 0px 0px 1px, rgba(43, 45, 80, 0.08) 0px 2px 5px 0px, rgba(0, 0, 0, 0.07) 0px 1px 1.5px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px",
                color: "rgb(77, 89, 105)",
                fontWeight: 500,
              }}
            >
              {"‹"}
            </Button>
            <Card.Title as="h4" className="m-0 ms-3">
              {header}
            </Card.Title>
          </div>
          {onNext && (
            <div>
              <Button
                variant="primary"
                size="sm"
                className="mx-2"
                style={{
                  backgroundColor: "rgb(0, 179, 104)",
                  borderColor: "rgb(0, 168, 98)",
                  boxShadow: "rgba(255, 255, 255, 0.15) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0,075) 0px 1px 1px 0px",
                  fontWeight: 500,
                }}
                onClick={onNext}
              >
                Next
              </Button>
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default CustomCard;
